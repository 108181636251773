var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.optOutId && _vm.isLoading
        ? _c("v-skeleton-loader", { attrs: { type: "image, article@3" } })
        : _c(
            "div",
            [
              _c(
                "v-card",
                {
                  style: { marginBottom: _vm.footerMarginTopComputed + "px" },
                  attrs: { id: "froalaContainer" }
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "opt-out-content" },
                    [
                      !_vm.editMode && !_vm.optOut.customLogo
                        ? _c("v-img", {
                            staticClass: "my-2 mx-auto",
                            attrs: {
                              src: require("@/assets/mms-opt-out.png"),
                              width: "500"
                            }
                          })
                        : _vm.optOut && _vm.optOut.customLogo
                        ? _c("v-img", {
                            staticClass: "my-2 mx-auto",
                            attrs: { src: _vm.optOut.customLogo, width: "500" }
                          })
                        : _vm._e(),
                      _vm.editMode
                        ? _c(
                            "div",
                            {
                              staticClass: "my-2 mx-auto d-flex justify-center"
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    right: "",
                                    "x-offset": "",
                                    origin: "right",
                                    transition: "slide-x-transition",
                                    "close-on-content-click": false,
                                    "close-on-click": false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var onMenu = ref.on
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var onTooltip = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  color:
                                                                    "success"
                                                                }
                                                              },
                                                              Object.assign(
                                                                {},
                                                                onMenu,
                                                                onTooltip
                                                              )
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-upload"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Upload Logo")
                                                ])
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1604517239
                                  ),
                                  model: {
                                    value: _vm.uploadLogoMenu,
                                    callback: function($$v) {
                                      _vm.uploadLogoMenu = $$v
                                    },
                                    expression: "uploadLogoMenu"
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { width: _vm.menuWidth } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "px-5 py-2" },
                                        [
                                          _c("v-file-input", {
                                            attrs: {
                                              accept:
                                                _vm.acceptedFileExtensions,
                                              "show-size": 1000,
                                              "error-messages":
                                                _vm.uploadLogoErrors,
                                              color: "secondary",
                                              label: "Upload Logo"
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.uploadLogoErrors = []
                                              }
                                            },
                                            model: {
                                              value: _vm.uploadLogo,
                                              callback: function($$v) {
                                                _vm.uploadLogo = $$v
                                              },
                                              expression: "uploadLogo"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "font-italic pl-7 inline-block"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Valid file extensions: " +
                                                  _vm._s(
                                                    _vm.acceptedFileExtensions
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "tertiary",
                                                disabled: _vm.uploadingLogo
                                              },
                                              on: { click: _vm.closeUploadLogo }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Cancel\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "success",
                                                loading: _vm.uploadingLogo
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.uploadLogoSave(
                                                    _vm.uploadLogo
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Upload\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("span", { staticClass: "font-weight-bold text-h6" }, [
                        _vm._v(_vm._s(_vm.email) + ",")
                      ]),
                      _c("p", [
                        _vm._v("If this is "),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("NOT")
                        ]),
                        _vm._v(
                          " your email address, please ignore this page since the email associated with this page was most likely forwarded to you."
                        )
                      ]),
                      !_vm.editMode && !_vm.optOut.customText
                        ? _c("div", [
                            _vm._v(
                              "\n          Med-E-Mail provides Physicians and Healthcare Professionals relevant marketing information providing you:\n          "
                            ),
                            _c("ol", [
                              _c("li", [
                                _vm._v(
                                  "CME announcements from leading Medical Institutions and Accredited Providers"
                                )
                              ]),
                              _c("li", [
                                _vm._v(
                                  "Job Opportunities from hospitals, group practices, and recruitment search firms"
                                )
                              ]),
                              _c("li", [
                                _vm._v("Medical Publishers free content")
                              ]),
                              _c("li", [
                                _vm._v(
                                  "Hospital marketing newsletters providing content from the top ranked hospitals"
                                )
                              ]),
                              _c("li", [_vm._v("Honoraria opportunities")]),
                              _c("li", [_vm._v("Market research")]),
                              _c("li", [_vm._v("E-detailing")]),
                              _c("li", [
                                _vm._v(
                                  "Clinical trial participation opportunities"
                                )
                              ]),
                              _c("li", [
                                _vm._v("Pharmaceutical advertisements")
                              ])
                            ])
                          ])
                        : _vm.editMode
                        ? _c(
                            "div",
                            [
                              _c("froala", {
                                attrs: { config: _vm.froalaConfig },
                                model: {
                                  value: _vm.optOut.customText,
                                  callback: function($$v) {
                                    _vm.$set(_vm.optOut, "customText", $$v)
                                  },
                                  expression: "optOut.customText"
                                }
                              })
                            ],
                            1
                          )
                        : _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.optOut.customText)
                            }
                          }),
                      _c("p", [
                        _vm._v(
                          "We’d hate to see you go, but if you wish to stop receiving all this information indicate by clicking the opt out button below:"
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-around" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.buttonLoading
                              },
                              on: { click: _vm.optOutClick }
                            },
                            [
                              _vm._v(
                                "\n            Opt out from all email\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.diplayedResult
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-around font-weight-bold text-h6 pt-4"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.diplayedResult) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-footer",
                {
                  staticClass: "d-flex justify-space-around",
                  style: { marginBottom: _vm.footerMarginBottom + "px" },
                  attrs: { absolute: "" }
                },
                [
                  !_vm.editMode && !_vm.optOut.customFooter
                    ? _c("div", [
                        _vm._v(
                          "\n        ©" +
                            _vm._s(_vm.currentYear) +
                            " Medical Marketing Service, Inc.  |  All rights reserved.  |  "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.mmslists.com/privacy-policy",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Privacy Policy")]
                        )
                      ])
                    : _vm.editMode
                    ? _c("v-textarea", {
                        attrs: {
                          autogrow: "",
                          "no-resize": "",
                          "full-width": "",
                          rows: "1",
                          "row-height": "32"
                        },
                        on: {
                          blur: function($event) {
                            return _vm.customFooterSave()
                          }
                        },
                        model: {
                          value: _vm.optOut.customFooter,
                          callback: function($$v) {
                            _vm.$set(_vm.optOut, "customFooter", $$v)
                          },
                          expression: "optOut.customFooter"
                        }
                      })
                    : _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.optOut.customFooter) }
                      })
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }