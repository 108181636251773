<template>
  <div>
    <v-skeleton-loader
      v-if="optOutId && isLoading"
      type="image, article@3"
    />
    <div
      v-else
    >
      <v-card
        id="froalaContainer"
        :style="{ marginBottom: footerMarginTopComputed + 'px' }"
      >
        <v-card-text class="opt-out-content">
          <v-img
            v-if="!editMode && !optOut.customLogo"
            src="@/assets/mms-opt-out.png"
            width="500"
            class="my-2 mx-auto"
          />
          <v-img
            v-else-if="optOut && optOut.customLogo"
            :src="optOut.customLogo"
            width="500"
            class="my-2 mx-auto"
          />
          <div
            v-if="editMode"
            class="my-2 mx-auto d-flex justify-center"
          >
            <v-menu
              v-model="uploadLogoMenu"
              right
              x-offset
              origin="right"
              transition="slide-x-transition"
              :close-on-content-click="false"
              :close-on-click="false"
            >
              <template v-slot:activator="{ on: onMenu }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                      icon
                      color="success"
                      v-on="{ ...onMenu, ...onTooltip }"
                    >
                      <v-icon>mdi-upload</v-icon>
                    </v-btn>
                  </template>
                  <span>Upload Logo</span>
                </v-tooltip>
              </template>
              <v-card :width="menuWidth">
                <v-card-text class="px-5 py-2">
                  <v-file-input
                    v-model="uploadLogo"
                    :accept="acceptedFileExtensions"
                    :show-size="1000"
                    :error-messages="uploadLogoErrors"
                    color="secondary"
                    label="Upload Logo"
                    @change="uploadLogoErrors = []"
                  />
                  <div class="font-italic pl-7 inline-block">
                    Valid file extensions: {{ acceptedFileExtensions }}
                  </div>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="tertiary"
                    :disabled="uploadingLogo"
                    @click="closeUploadLogo"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="success"
                    :loading="uploadingLogo"
                    @click="uploadLogoSave(uploadLogo)"
                  >
                    Upload
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
          <span class="font-weight-bold text-h6">{{ email }},</span>
          <p>If this is <span class="font-weight-bold">NOT</span> your email address, please ignore this page since the email associated with this page was most likely forwarded to you.</p>
          <div v-if="!editMode && !optOut.customText">
            Med-E-Mail provides Physicians and Healthcare Professionals relevant marketing information providing you:
            <ol>
              <li>CME announcements from leading Medical Institutions and Accredited Providers</li>
              <li>Job Opportunities from hospitals, group practices, and recruitment search firms</li>
              <li>Medical Publishers free content</li>
              <li>Hospital marketing newsletters providing content from the top ranked hospitals</li>
              <li>Honoraria opportunities</li>
              <li>Market research</li>
              <li>E-detailing</li>
              <li>Clinical trial participation opportunities</li>
              <li>Pharmaceutical advertisements</li>
            </ol>
          </div>
          <div v-else-if="editMode">
            <froala
              v-model="optOut.customText"
              :config="froalaConfig"
            />
          </div>
          <div
            v-else
            v-html="optOut.customText"
          />
          <p>We’d hate to see you go, but if you wish to stop receiving all this information indicate by clicking the opt out button below:</p>
          <div
            class="d-flex justify-space-around"
          >
            <v-btn
              color="primary"
              :loading="buttonLoading"
              @click="optOutClick"
            >
              Opt out from all email
            </v-btn>
          </div>
          <div
            v-if="diplayedResult"
            class="d-flex justify-space-around font-weight-bold text-h6 pt-4"
          >
            {{ diplayedResult }}
          </div>
        </v-card-text>
      </v-card>
      <v-footer
        absolute
        class="d-flex justify-space-around"
        :style="{ marginBottom: footerMarginBottom + 'px' }"
      >
        <div v-if="!editMode && !optOut.customFooter">
          ©{{ currentYear }} Medical Marketing Service, Inc.  |  All rights reserved.  |  <a
            href="https://www.mmslists.com/privacy-policy"
            target="_blank"
          >Privacy Policy</a>
        </div>
        <v-textarea
          v-else-if="editMode"
          v-model="optOut.customFooter"
          autogrow
          no-resize
          full-width
          rows="1"
          row-height="32"
          @blur="customFooterSave()"
        />
        <div
          v-else
          v-html="optOut.customFooter"
        />
      </v-footer>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import '@/plugins/vue-froala-wysiwyg'
import moment from 'moment'
import { azBlobService, mailingService, tenantService } from '@/shared/services'
import { fileExtensions } from '@/shared/models/content-library'
import { wscMixin } from '@/shared/mixins/general'

export default {
  name: 'OptOutPreview',

  mixins: [wscMixin],

  props: {
    optOutId: {
      type: String,
      default: null,
      required: false
    },
    email: {
      type: String,
      default: 'test@mmslists.com',
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    responseMessage: {
      type: String,
      default: null,
      required: false
    },
    optOutData: {
      type: Object,
      default: () => {},
      required: false
    },
    editMode: {
      type: Boolean,
      default: false,
      required: false
    },
    footerMarginTop: {
      type: Number,
      default: 48,
      requried: false
    },
    footerMarginBottom: {
      type: Number,
      default: 0,
      requried: false
    },
    isLive: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data () {
    return {
      loadingOptOut: false,
      menuWidth: 500,
      uploadLogoMenu: false,
      buttonLoading: false,
      result: null,
      uploadLogoErrors: [],
      uploadingLogo: false,
      optOut: {
        customLogo: null,
        customText: '',
        customFooter: ''
      }
    }
  },

  computed: {
    froalaConfig () {
      const context = this
      return {
        toolbarButtons: {
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'inlineClass',
              'inlineStyle',
              'clearFormatting'
            ]
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'formatOLSimple',
              'alignRight',
              'alignJustify',
              'formatOL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote'
            ]
          },
          moreRich: {
            buttons: [
              'insertLink',
              'insertTable',
              'emoticons',
              'specialCharacters',
              'insertHR'
            ]
          },
          moreMisc: {
            'buttons': ['undo', 'redo', 'html', 'print', 'spellChecker', 'selectAll', 'help'],
            'align': 'right'
          }
        },
        pluginsEnabled: [
          'align',
          'charCounter',
          'codeBeautifier',
          'codeView',
          'colors',
          'draggable',
          'fontFamily',
          'fontSize',
          'fullscreen',
          'image',
          'imageTUI',
          'lineBreaker',
          'emoticons',
          'entities',
          'inlineStyle',
          'inlineClass',
          'lineHeight',
          'lists',
          'link',
          'paragraphFormat',
          'paragraphStyle',
          'quickInsert',
          'specialCharacters',
          'quote',
          'table',
          'wordPaste',
          'print'
        ],
        tableEditButtons: [
          'tableHeader',
          'tableRemove',
          '|',
          'tableRows',
          'tableColumns',
          'tableStyle',
          '-',
          'tableCells',
          'tableCellBackground',
          'tableCellVerticalAlign',
          'tableCellHorizontalAlign',
          'tableCellStyle'
        ],
        htmlAllowedTags: ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote',
          'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog',
          'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'font', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
          'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main',
          'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param',
          'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source', 'span',
          'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track',
          'u', 'ul', 'var', 'video', 'wbr'],
        events: {
          'initialized': function () {
            // DM@20200226: Added try / catch to stop an error with WEBSPELLCHECKER not defined.
            try {
              WEBSPELLCHECKER.init({
                container: this.el
              })
            } catch (exception) {
              console.log(exception)
            }
          },
          'blur': function (e) {
            context.customTextSave()
          }
        },
        htmlRemoveTags: ['script'],
        fullPage: true,
        spellcheck: true,
        pastePlain: true,
        attribution: false,
        scrollableContainer: '#froalaContainer',
        heightMax: 300,
        placeholderText: 'Edit Your Opt Out Content Here!',
        key: process.env.VUE_APP_FROALA_LICENSE_KEY
      }
    },
    isLoading () {
      return this.loadingOptOut || this.loading
    },
    currentYear () {
      return moment().format('YYYY')
    },
    acceptedFileExtensions () {
      return fileExtensions.getExtensionsString(fileExtensions.imageExtensionsList)
    },
    footerMarginTopComputed () {
      return this.editMode ? parseInt(this.footerMarginTop) + 64 : parseInt(this.footerMarginTop)
    },
    diplayedResult () {
      return this.responseMessage || this.result
    }
  },

  watch: {
    optOutId: {
      immediate: true,
      handler (newValue, oldValue) {
        if (!newValue) {
          this.optOut = {
            customLogo: null,
            customText: '',
            customFooter: ''
          }
          return
        }
        this.loadingOptOut = true
        mailingService.getOptOut(newValue).then(resp => {
          Vue.set(this, 'optOut', resp)
          this.loadingOptOut = false
        }).finally(() => {
          this.loadingOptOut = false
        })
      }
    },
    editMode (newValue, oldValue) {
      if (oldValue) {
        this.customTextSave()
        this.customFooterSave()
      }
    },
    responseMessage (newValue, oldValue) {
      if (newValue) {
        this.buttonLoading = false
      }
    },
    result (newValue, oldValue) {
      if (newValue) {
        this.buttonLoading = false
      }
    }
  },

  methods: {
    async customTextSave () {
      const data = await tenantService.setOptOutValue(this.optOutId, 'customText', this.optOut.customText)
    },
    async customFooterSave () {
      const data = await tenantService.setOptOutValue(this.optOutId, 'customFooter', this.optOut.customFooter)
    },
    closeUploadLogo () {
      this.uploadLogoMenu = false
      this.uploadLogo = null
    },
    async uploadLogoSave (file) {
      const copy = new File([file], `${this.optOutId}${fileExtensions.getExtension(file.name)}`, { type: file.type })

      this.uploadLogoErrors = []
      let formData = new FormData()
      formData.append('optOutId', this.optOutId)
      formData.append('tenantCrmId', this.$store.getters['simulatedCustomerCrmId']())
      formData.append('files[0]', copy)
      this.uploadingLogo = true
      await tenantService.uploadOptOutLogo(formData)
        .then(resp => {
          if (resp.errorResponse) {
            this.uploadLogoErrors.push(resp.errorResponse)
          } else {
            Vue.set(this.optOut, 'customLogo', resp)
            this.closeUploadLogo()
          }
        })
        .finally(() => {
          this.uploadingLogo = false
        })
    },
    optOutClick () {
      this.buttonLoading = true
      if (this.isLive) {
        this.$emit('opt-out-recipient')
      } else {
        this.result = null
        setTimeout(() => (this.result = 'Sample results here!'), 2000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.opt-out-content {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
